<template>
    <div class="educational view-container">
        <div class="view-content">
            <action-bar title="Educação financeira" subtitle="Trilhas de aprendizado" />

            <p>
                Aqui você encontra desde conceitos básicos sobre finanças pessoais até dicas e conhecimentos avançados,
                para você se tornar um expert no assunto
            </p>

            <trail
                v-bind="trail"
                layoutType="full-thumb"
                v-for="(trail, index) in learningTrails"
                :key="index"
                @show-video="playVideo"
            />
        </div>

        <div v-show="!!selectedVideo" class="overlay" @click="selectedVideo = ''">
            <div class="embed-video">
                <youtube-iframe
                    v-bind="ytIframeData"
                    :video-id="selectedVideo"
                    @state-change="(event) => handleVideoStatusChange(event, selectedVideo)"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { mainStore } from '../../store'
import { YoutubeIframe } from '@vue-youtube/component'
import { useRoute } from 'vue-router'

import ActionBar from '../../components/ActionBar.vue'
import Trail from '../../components/educational/Trail.vue'

import util from '../../common/util'

const store = mainStore()
const route = useRoute()

const learningTrails = ref([])
const selectedVideo = ref('')
const loading = ref(true)

const ytIframeData = computed(() => {
    if (store.smallScreen)
        return {
            width: '100%',
            height: 200,
        }

    return { width: 1024, height: 576 }
})

const videosWatched = computed(() => {
    return store.user.videosWatched || []
})

function playVideo(videoId) {
    selectedVideo.value = videoId
}

function _setup() {
    learningTrails.value = []
    selectedVideo.value = ''

    const trail = structuredClone(store.trails.find((trail) => trail._id === route.params.id))

    if (!trail) return

    trail.items.forEach((item, index) => {
        let prev = []
        if (index) {
            prev = [...learningTrails.value[index - 1].data]
            if (learningTrails.value[index - 1].markedItem) prev.push(learningTrails.value[index - 1].markedItem)
        }

        const markedItem = item.documents.find((document) => !videosWatched.value.includes(document.videoId))
        const data = item.documents.filter((document) => document.videoId !== markedItem?.videoId)

        learningTrails.value.push({
            data,
            markedItem,
            loading: false,
            title: item.title,
            description: item.description,
            locked: !index
                ? false
                : prev.filter((item) => videosWatched.value.includes(item.videoId)).length < prev.length,
        })
    })
}

const handleVideoStatusChange = (event, selectedVideo) => {
    if (!event.data && selectedVideo) {
        util.handleYtVideoStatusChange(event, selectedVideo)
            .then(() => _setup())
            .catch(console.error)
    }
}

watch(
    () => store.trails,
    () => _setup(),
)

watch(
    () => store.user,
    () => _setup(),
)

onMounted(() => {
    _setup()
})
</script>

<style lang="scss" scoped>
@import url('./styles.scss');
</style>
