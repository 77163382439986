<template>
    <div class="trail">
        <h1 class="title">
            <span>{{ title }}</span>
            <i v-if="locked && type !== 'presentation-video'" class="fas fa-lock"></i>
        </h1>
        <h2 class="description">{{ description }}</h2>

        <div class="_loader" v-if="_loading">
            <div v-if="showProgressBar" class="loader-progress-container">
                <v-skeleton-loader style="margin-top: 3px" type="text" />
            </div>

            <div class="_loader-cards-container">
                <v-skeleton-loader
                    v-bind="loadingProps"
                    v-for="n in 3"
                    :key="n"
                    :class="[type]"
                    style="margin: 12px 12px 0 0"
                />
            </div>
        </div>

        <div v-else-if="!locked && !_loading" @click="$emit('open')">
            <div v-if="showProgressBar" class="progress-bar-container" style="padding: 0">
                <div>
                    <p class="label">{{ percentage.toFixed(0) + '%' }} completado</p>

                    <div class="progress-bar-border">
                        <div class="progress-bar green-background" :style="{ width: percentage + '%' }"></div>
                    </div>
                </div>
            </div>

            <post
                v-if="markedItem"
                :post="markedItem"
                :isVideo="video"
                :type="type"
                :marked="true"
                :layoutType="layoutType"
                @open-post="openPost"
            />

            <div class="post-group" :class="[{ gutter: !showProgressBar, reduced: !!markedItem }]">
                <post
                    v-for="(post, index) in _data"
                    :key="index"
                    :post="post"
                    :type="type"
                    :isVideo="video"
                    :layoutType="layoutType"
                    @open-post="openPost"
                />
            </div>
        </div>

        <div v-else-if="locked && type === 'presentation-video'">
            <div class="post locked">
                <ion-icon style="font-size: 40px" name="lock-closed-outline"></ion-icon>
                <div class="locked-text">Em breve...</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import Post from './Post.vue'

import util from '../../common/util'

export default {
    name: 'Trail',

    components: { Post },

    emits: ['show-video', 'open'],

    props: {
        title: String,
        description: String,
        loading: Boolean,
        _id: String,

        data: {
            type: Array,
            default: () => [],
        },

        markedItem: Object,
        layoutType: String,

        showProgressBar: {
            type: Boolean,
            default: true,
        },

        locked: {
            type: Boolean,
            default: true,
        },

        type: {
            type: String,
            default: 'video',
        },
    },

    computed: {
        ...mapState(mainStore, ['waiting', 'user', 'allTrailData']),

        watched() {
            const array = this.type === 'presentation-video' ? this.allTrailData(this._id) : this.data
            return array.filter((item) => this.videosWatched.includes(item.videoId)).length
        },

        allVideosLength() {
            if (this.type === 'presentation-video') return this.allTrailData(this._id).length
            return this.data.length + (!!this.markedItem ? 1 : 0)
        },

        percentage() {
            return util.percentage(this.watched, this.allVideosLength)
        },

        loadingProps() {
            switch (this.type) {
                case 'ebook':
                    return {
                        width: '200px',
                        height: '320px',
                        type: 'image',
                    }
                default:
                    return {
                        width: '320px',
                        height: '250px',
                        type: 'card',
                    }
            }
        },

        _loading() {
            return this.waiting && this.loading
        },

        video() {
            return ['presentation-video', 'video'].includes(this.type)
        },

        videosWatched() {
            return this.user.videosWatched || []
        },

        _data() {
            switch (this.type) {
                default:
                    return this.data
            }
        },
    },

    methods: {
        openPost(data) {
            switch (this.type) {
                case 'video':
                    this.$emit('show-video', data.videoId)
                    return
                case 'ebook':
                    const routerData = this.$router.resolve({ name: 'ebooks', params: { id: data.id } })
                    window.open(routerData.href, '_blank')
                    return
                case 'presentation-video':
                    return
                default:
                    window.open(data.link, '_blank')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@include easy-progress-bar();
@include easy-triangle();

.trail {
    margin-bottom: 24px;

    .title {
        font-size: 18px;
        font-weight: bold;
        margin: 8px 0;

        .fa-lock {
            margin-left: 16px;
            color: $dark-grey;
        }
    }

    .description,
    .title {
        color: $dark-blue;
    }

    ._loader {
        width: 100%;

        .loader-progress-container {
            margin: 12px 0;
            justify-content: flex-end;
            width: 600px;
        }

        ._loader-cards-container {
            display: flex;
        }
    }

    .progress-bar-container {
        margin: 12px 0 16px;
        width: 600px;

        .label {
            color: $medium-grey;
            margin-bottom: 2px;
        }

        .green-background {
            background-color: lime;
        }
    }

    .post-group {
        display: flex;
        width: 100%;
        margin-bottom: 12px;
        flex-wrap: nowrap;
        overflow: hidden;

        &.reduced {
            width: 984px;
        }

        &:hover {
            overflow-x: scroll;
        }

        &.gutter {
            margin-top: 16px;
        }
    }

    .locked {
        width: 320px;
        min-width: 320px;
        margin-top: 16px;
        height: 180px;
        flex-direction: column;
        color: $darker-blue;
        background-color: $lighter-grey;
        margin-right: 12px;
        padding-bottom: 8px;
        position: relative;
        @include flex-center();

        .locked-text {
            margin-top: 16px;
            font-size: 18px;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 700px) {
    .loader-progress-container,
    .progress-bar-container {
        width: 100% !important;
    }

    ._loader-cards-container {
        & > div {
            min-width: 320px;
        }
    }
    .post-group {
        overflow: scroll !important;
        overflow-y: hidden !important;
    }
}
</style>
